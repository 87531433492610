import { useEffect, useState } from 'react'

import { GetShowsWithMissingMetadata, ImageWithProfile } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Custom/Button/Button'
import Checkbox from 'src/components/Custom/Checkbox/Checkbox'
import ContentListingTable from 'src/components/Custom/ContentListingTable/ContentListingTable'
import DatePicker from 'src/components/Custom/DatePicker/DatePicker'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import { defaultCountryCode } from 'src/lib/country'
import { getImage } from 'src/lib/images'

import { GET_SHOWS_WITH_MISSING_METADATA } from '../queries'

import { missingMetadataHeader } from './constants'

const MissingMetadatListing = () => {
  const [dates, setDates] = useState({
    start: null,
    end: null,
  })
  const [shows, setShows] = useState([])
  const [filters, setFilters] = useState({
    limit: 25,
    skip: 0,
    sortBy: {
      value: 'earliestFullLengthContentAvailableAt',
      sort: 'desc',
    },
    hasAvailableFullLengthContent: [defaultCountryCode],
  })

  const { data } = useQuery<GetShowsWithMissingMetadata>(
    GET_SHOWS_WITH_MISSING_METADATA,
    {
      variables: {
        ...dates,
        filters: {
          ...filters,
          sortBy: {
            [filters.sortBy.value]: filters.sortBy.sort,
          },
        },
      },
    }
  )

  useEffect(() => {
    if (data && data.missingMetadataShows) {
      setShows(
        data.missingMetadataShows.map((show) => ({
          ...show,
          image: getImage(show.images as Array<ImageWithProfile>).image,
          emptyFields: 'GenreVibes',
        }))
      )
    }
  }, [data])

  const onClickEditItem = (itemData) =>
    navigate(routes.editGlobalShow({ id: itemData.id }))

  const handlePageChange = (toPage: number) => {
    const currentPage = filters.skip / filters.limit
    if (
      toPage > -1 &&
      (data.missingMetadataShows.length === filters.limit ||
        toPage < currentPage)
    ) {
      setFilters((old) => ({ ...old, skip: toPage * old.limit }))
    }
  }

  const actionsList = (itemData) => {
    return (
      <div className="flex flex-col p-2">
        <Button
          title="Edit"
          onClick={() => onClickEditItem(itemData)}
          backgroundColor="bg-blue-600"
          customButtonClass="mb-2"
        />
      </div>
    )
  }

  return (
    <div className="w-full p-4">
      <Metadata title="Shows Missing Metadata" />
      <HeadingLevel level={1}>Shows Missing Metadata</HeadingLevel>
      <div className="mt-5">
        <div>
          PBS genres are not editable. However, admins may override the Genre on
          each Show (which feeds down to child Videos).
        </div>
        <div className="flex items-center pb-4 pt-6">
          <div>
            <DatePicker
              onChange={(dates) =>
                setDates({ start: dates.startDate, end: dates.endDate })
              }
              value={{
                startDate: dates.start,
                endDate: dates.end,
              }}
              primaryColor={'blue'}
              displayFormat={'MM/DD/YYYY'}
              placeholder="Date range"
              inputClass="w-[230px]"
            />
            <Checkbox
              id="exclude-shows-without-full-length-content"
              title="Exclude shows without full-length content"
              onValueChange={(updatedValue) =>
                setFilters({
                  ...filters,
                  hasAvailableFullLengthContent: updatedValue
                    ? [defaultCountryCode]
                    : [],
                })
              }
              defaultValue={filters.hasAvailableFullLengthContent?.length > 0}
              checkboxContainerClass="pt-4"
            />
          </div>
        </div>

        <ContentListingTable
          contentList={shows}
          tableHeading={missingMetadataHeader}
          handleSort={({ sortBy, toggle }) =>
            setFilters((old) => ({
              ...old,
              sortBy: {
                value: sortBy,
                sort: toggle.toUpperCase(),
              },
            }))
          }
          actionsList={actionsList}
          paginationConfig={{
            size: filters.limit,
            page: filters.skip / filters.limit,
          }}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default MissingMetadatListing
