export const GET_SHOWS_WITH_MISSING_METADATA = gql`
  query GetShowsWithMissingMetadata(
    $start: Date
    $end: Date
    $filters: FilterShowInput
  ) {
    missingMetadataShows(start: $start, end: $end, filters: $filters) {
      earliestFullLengthContentAvailableAt
      id
      images
      title
    }
  }
`
